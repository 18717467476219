import Message from '../message'
import Header from './Header'
import ResponsiveAppBar from '../navbars/navbar';
import Album from '../examples/Album';
import Home from '../examples/onepirate/Home'

function App() {
  return <div>
    {/* <Home/> */}
    <h1>hello</h1>
    </div>;
}

export default App;