import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App'
import 'bootstrap/dist/css/bootstrap.css'
import Temp from './temp/src';
import reportWebVitals from './temp/src/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Temp/>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

reportWebVitals();

