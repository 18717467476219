import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Typography from '@mui/material/Typography';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

const iconStyle = {
  width: 28,
  height: 28,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'end',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const iconPhone = {
  width: 18,
  height: 18,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'end',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const  appBar = {
  height: 200,
  width: `calc(100% - 200px)`,
  marginLeft: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        
        <Toolbar sx={{ justifyContent: 'space-between' }}>
   
          <CallIcon/> 
          {/* <Button variant="outlined" color="inherit" >
          206&#8211;552&#8211;0261
        </Button> */}
          <h9>(206)&nbsp;</h9>
          <h9>552&#8211;</h9>
          <h9>0261</h9>
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
            >
              
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              href="/premium-themes/onepirate/"
              sx={{ fontSize: 24 }}
            >
              {'Shaniz Bakery'}
          </Link>
            </Grid>        
            
          <Box color="#4267B2" component="a" href="https://www.facebook.com/ShanizBakery/" sx={iconStyle}>
          <FacebookIcon  />
          </Box>

          <Box color="#C13584" component="a" href="https://www.instagram.com/shanizbakery/" sx={iconStyle}>
            <InstagramIcon />
          </Box>
 
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
