import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";
import { PhoneIcon } from "@chakra-ui/icons";
import ProfileArray from "./ProfileArray";

export default function Contact({ color }) {
  const profile = ProfileArray();
  const linkedin = () => {
    window.open(`${profile.linkedin}`, "_blank", "noreferrer,noopener");
  };
  const github = () => {
    window.open(`${profile.github}`, "_blank", "noreferrer,noopener");
  };
  const email = () => {
    window.open(`mailto:${"info@shanizbakery.com"}`, "_blank", "noreferrer,noopener");
  };

  const facebook = () => {
    window.open(`${"https://www.facebook.com/ShanizBakery/"}`, "_blank", "noreferrer,noopener");
  };

  const instagram = () => {
    window.open(`${"https://www.instagram.com/shanizbakery/"}`, "_blank", "noreferrer,noopener");
  };


  return (
    <>
      <Container maxW={"900px"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Divider orientation="horizontal" />
          <Stack align="center">
            <HStack>
            <Text as='b' fontSize={35}>Contact</Text>
            </HStack>
            
          </Stack>
          {/* <Divider orientation="horizontal" /> */}
          <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
            {/* <Heading fontSize={"3xl"}>Call or Email Us</Heading> */}
            <Text color={`${color}.500`} fontWeight={600} fontSize={"lg"} px={4}>
              <PhoneIcon/> (206) 552 – 0261
            </Text>

            <Text color={`${color}.500`} fontWeight={600} fontSize={"lg"} px={4}>
            info@shanizbakery.com
            </Text>
            <Center>
              <HStack pt={4} spacing={4}>
                {/* <FaLinkedin onClick={linkedin} size={28} />
                <FaGithub onClick={github} size={28} /> */}
                <FaEnvelope onClick={email} size={28} />
                <FaFacebook onClick={facebook} size={28} />
                <FaInstagram onClick={instagram} size={28} />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

