import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Image,
  Heading,
  SimpleGrid,
  Badge,
  Link,
  Center,
} from "@chakra-ui/react";
import { Fade } from "react-reveal";
import { useState } from "react";
import ProjectsArray from "./ProjectsArray";
import OtherProjectsArray from "./OtherProjectsArray";
import TagsArray from "./TagsArray";

import StrawberryRollet from '../../../images/rollet_strawberry.jpg';
import DanmarkiSide from '../../../images/danmarki_whole_side_tray.jpg';
import CreamPuffs from '../../../images/cream_puffs.jpg';
import NescafeRollet from '../../../images/whole_nescafe_rollet.jpg';
import MixSheriniKeshmeshi from '../../../images/mix_keshmeshi.jpg';
import MixSheriniPapyouni from '../../../images/mix_sherini_1.jpg';
import BananaWalnut from '../../../images/banana_walnut_cake.jpg';
import Owners from '../../../images/shaniz_owners.jpg';
import MochaRollet from '../../../images/mocha_rollet.jpg';

export default function Projects({ color }) {
    const projects = ProjectsArray();
    const others = OtherProjectsArray();
    const options = TagsArray("ProjectsTags");
    
    const [selected, setSelected] = useState("All");

    const handleSelected = (value) => {
      setSelected(value);
    };
    
  return (
    <>

      <Container maxW={"800px"} id="projects">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center">
            <HStack mx={4}>
              <Text as='b' fontSize={35}>Gallery</Text>
            </HStack>
           
          </Stack>
          <Divider orientation="horizontal" />

          <SimpleGrid minChildWidth='220px' spacing={2}>

          <Fade bottom>
            <Image height={180} src={StrawberryRollet} />
          </Fade>

          <Fade bottom>
            <Image height={180} src={DanmarkiSide} />
          </Fade >

          <Fade bottom>
            <Image height={180} src={CreamPuffs} />
          </Fade>         

          <Fade bottom>
              <Image height={180} src={MixSheriniKeshmeshi} />
            </Fade>

            <Fade bottom>
              <Image height={180} src={MixSheriniPapyouni} />
            </Fade>

            <Fade bottom>
              <Image height={180} src={BananaWalnut} />
            </Fade>

            <Fade bottom>
              <Image width={300} height={180} src={NescafeRollet} />
            </Fade>

            <Fade bottom>
              <Image width={300} height={180} src={MochaRollet} />
            </Fade>

            <Fade bottom >
              <Image width={300} height={180} src={MochaRollet} />
            </Fade>

          </SimpleGrid>
          
        </Stack>
      </Container>
    </>
  );
}
