import Typography from '../components/Typography';
import Container from '@mui/material/Container';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';

export default function WhereToBuy() {
    return (
        <Container component="section" sx={{ mt: 8, mb: 5 }}>
        <Typography variant="h4" marked="center" align="center" component="h3">
        Where To Purchase
        </Typography>

        <Typography
                color="inherit"
                marked="center" 
                align="center"
              >
                <b>Rose Market</b> <LocationOnIcon /> 12005 NE 12th St, Bellevue, WA 98005 <CallIcon /> (206) 900-3458
        </Typography>
        <Typography
                color="inherit"
                marked="center" 
                align="center"
              >
                <b>Oskoo Market</b> <LocationOnIcon /> 14100 NE 20th St, Bellevue, WA 98007 <CallIcon /> (425) 746-3061
        </Typography>
        <Typography
                color="inherit"
                marked="center" 
                align="center"
              >
                <b>Sahand Market</b> <LocationOnIcon /> 12047 124th Ave NE, Kirkland, WA 98034 <CallIcon /> (425) 823-3194
        </Typography>
        <Typography
                color="inherit"
                marked="center" 
                align="center"
              >
                <b>Persepolis Grill</b> <LocationOnIcon /> 5517 University Way NE, Seattle, WA 98105 <CallIcon /> (206) 524-3434
        </Typography>
        </Container>
        
);
}