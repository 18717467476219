import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useColorModeValue,
  Stack,
  useColorMode,
  IconButton,
  useMediaQuery,
  useDisclosure,
  HStack,
  Link,
  Box,
  Text
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, HamburgerIcon } from "@chakra-ui/icons";

import { FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";
import { useState } from "react";
import ProfileArray from "./ProfileArray";
const TbIcons = require("react-icons/tb");

export default function Nav({ color }) {
  const profile = ProfileArray();
  const colors = {
  "blue": "#3182CE", 
  "cyan": "#00B5D8", 
  "gray": "#718096", 
  "green": "#38A169", 
  "orange": "#DD6B20", 
  "pink": "#D53F8C", 
  "purple": "#805AD5", 
  "red": "#E53E3E", 
  "teal": "#319795", 
  "yellow": "#D69E2E"};
  const [scroll, setScroll] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const scrollToHero = () => {
  const heroSection = document.querySelector("#hero");
    heroSection.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAbout = () => {
    const aboutSection = document.querySelector("#about");
    aboutSection.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToExperience = () => {
    const experienceSection = document.querySelector("#experience");
    experienceSection.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToProjects = () => {
    const projectsSection = document.querySelector("#projects");
    projectsSection.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToContact = () => {
    const contactSection = document.querySelector("#contact");
    contactSection.scrollIntoView({ behavior: "smooth" });
  };
  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  const email = () => {
    window.open(`mailto:${"info@shanizbakery.com"}`, "_blank", "noreferrer,noopener");
  };

  const facebook = () => {
    window.open(`${"https://www.facebook.com/ShanizBakery/"}`, "_blank", "noreferrer,noopener");
  };

  const instagram = () => {
    window.open(`${"https://www.instagram.com/shanizbakery/"}`, "_blank", "noreferrer,noopener");
  };

  window.addEventListener("scroll", changeScroll);

  const TbLetterComponents = [];

  for (let i = 0; i < profile.logo.length; i++) {
    const letter = profile.logo[i];
    const component = TbIcons[`TbLetter${letter}`];
    TbLetterComponents.push(component);
  }

  return (
    <>
      <Flex
        bg={useColorModeValue("gray.100", "gray.900")}
        px={10}
        h={16}
        boxShadow={scroll ? "base" : "none"}
        zIndex="sticky"
        position="fixed"
        as="header"
        alignItems={"center"}
        justifyContent={"space-between"}
        w="100%"
      >
        {/* <Link onClick={scrollToHero}> */}
          <HStack pt={4} spacing={5}>
            {/* {TbLetterComponents.map((Component, index) => (
              <Component key={index} color={colors[color]} />
            ))} */}
          <FaEnvelope onClick={email} size={28} />
          <FaFacebook onClick={facebook} size={28} />
          <FaInstagram onClick={instagram} size={28} />           
          </HStack>
        {/* </Link> */}
        

        {/* <Stack align="center" p={14}>
            <HStack mx={4}>
              <Text as="i" fontWeight={900}>Welcome to Shaniz Bakery</Text>
            </HStack>

          </Stack> */}
          {/* <Text as="i" fontWeight={600}>placeholder</Text> */}

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            {isLargerThanMD ? (
              <>
              <Button variant="ghost" onClick={scrollToProjects}>
                  Gallery
                </Button>
                {/* <Button variant="ghost" onClick={scrollToAbout}>
                  About
                </Button> */}
                <Button variant="ghost" onClick={scrollToExperience}>
                  Where To Buy
                </Button>
                <Button variant="ghost" onClick={scrollToContact}>
                  Contact
                </Button>
              </>
            ) : (
              <></>
            )}
            {/* <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button> */}

            {isLargerThanMD ? (
              <></>
            ) : (
              <>
                <Button
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                ></Button>
                <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerBody>
                      {/* <Button variant="ghost" onClick={scrollToAbout}>
                        About
                      </Button> */}
                      <Button variant="ghost" onClick={scrollToExperience}>
                        Where To Buy
                      </Button>
                      <Button variant="ghost" onClick={scrollToProjects}>
                        Projects
                      </Button>
                      <Button variant="ghost" onClick={scrollToContact}>
                        Contact
                      </Button>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}
