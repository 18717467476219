import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Badge,
  Image,
  List,
  ListItem,
  ListIcon,
  Button,
  ButtonGroup,
  SimpleGrid,
  Center,
} from "@chakra-ui/react";
import { ChevronRightIcon, PhoneIcon } from "@chakra-ui/icons";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import ExperienceArray from "./ExperienceArray";
import TagsArray from "./TagsArray";

export default function Experience({ color }) {
  const experience = ExperienceArray();
  const options = TagsArray("ExperienceTags");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (options.length > 0) {
      setSelected(options[0].value);
    }
  }, [options]);
  
  const handleSelected = (value) => {
    setSelected(value);
  };

  return (
    <>
      <Container maxW={"900px"} id="experience">
        <Stack
          as={Box}
          textAlign={"center"}
          // spacing={{ base: 8, md: 14 }}
          // pb={{ base: 20, md: 36 }}
        >
          <Stack align="center">
            <HStack mx={4}>
            <Text as='b' fontSize={35}>Where&nbsp;To&nbsp;Buy</Text>
            </HStack>
           
          </Stack>
          <Divider orientation="horizontal" />
          {/* <Center px={4}>
            <ButtonGroup variant="outline">
              {options.map((option) => (
                <Button
                  colorScheme={selected === option.value ? `${color}` : "gray"}
                  onClick={() => handleSelected(option.value)}
                >
                  {option.value}
                </Button>
              ))}
            </ButtonGroup>
          </Center> */}
          <SimpleGrid columns={1} spacing={0}>

          
          <Text><Text as='b' fontSize={20}>Rose Market</Text><LocationOnIcon/>12005 NE 12th St, Bellevue, WA 98005 &nbsp;<PhoneIcon/>&nbsp;(206)&nbsp;900-3458</Text>

   
          <Text> <Text as='b' fontSize={20}>Oskoo Market</Text><LocationOnIcon/>14100 NE 20th St, Bellevue, WA 98007 &nbsp;<PhoneIcon/>&nbsp;(425) 746-3061</Text>


          <Text><Text as='b' fontSize={20}>Sahand Market</Text><LocationOnIcon/>12047 124th Ave NE, Kirkland, WA 98034 &nbsp;<PhoneIcon/>&nbsp;(425) 823-3194</Text>

          <Text><Text as='b' fontSize={20}>Persepolis Grill</Text><LocationOnIcon/>5517 University Way NE, Seattle, WA 98105 &nbsp;<PhoneIcon/>&nbsp;(206)&nbsp;900-3458</Text>


            {/* <Text as="bold" fontWeight={600}>Rose Market</Text>
            <box p={10}>
             <Text><LocationOnIcon/>&nbsp;12005&nbsp;NE&nbsp;12th&nbsp;St,&nbsp;Bellevue,&nbsp;WA&nbsp;98005&nbsp;&nbsp;&nbsp;<PhoneIcon/>&nbsp;(206)&nbsp;900-3458</Text>
            </box> */}

{/* 
          <Stack align="center" direction="row" p={10}>
              <Text fontWeight={600}>Rose Market</Text>
              <Text fontWeight={600}><LocationOnIcon/>&nbsp;12005&nbsp;NE&nbsp;12th&nbsp;St,&nbsp;Bellevue,&nbsp;WA&nbsp;98005&nbsp;</Text>
              <Text fontWeight={600}><PhoneIcon/>&nbsp;(206)&nbsp;900-3458</Text>
          </Stack> */}
                  
          </SimpleGrid>
        </Stack>

      </Container>
    </>
  );
}
